let activatedImage = null;

const targetVideoComponentGenerator = (onToggleContent, contentToggleSub) => ({
  schema: {
    name: { type: 'string' },
    video: { type: 'string' },
    thumb: { type: 'string' },
  },
  init() {
    const { object3D } = this.el
    const { name } = this.data
    object3D.visible = false
    const thumb = this.data.thumb && document.querySelector(this.data.thumb)
    const { el } = this
    el.setAttribute('material', 'src', thumb)
    el.setAttribute('class', 'cantap')
    // let playing = false

    this.subscription = contentToggleSub.subscribe(() => {
      // this.el.sceneEl.play();
    })

    el.addEventListener('click', () => {
      console.log('el.addEventListener', activatedImage);
      if (onToggleContent && !!activatedImage) {
        onToggleContent({ id: activatedImage });
        // this.el.sceneEl.pause();
      }
    })

    const showImage = ({ detail }) => {
      console.log("detail", detail.name);
      if (name !== detail.name) {
        return
      }
      activatedImage = detail.name;

      object3D.position.copy(detail.position)
      object3D.quaternion.copy(detail.rotation)
      object3D.scale.set(detail.scale, detail.scale, detail.scale)
      object3D.visible = true
    }

    const hideImage = ({ detail }) => {
      if (name !== detail.name) {
        return
      }
      object3D.visible = false
    }

    this.el.sceneEl.addEventListener('xrimagefound', showImage)
    this.el.sceneEl.addEventListener('xrimageupdated', showImage)
    this.el.sceneEl.addEventListener('xrimagelost', hideImage)
  },
  remove() {
    if (!!this.subscription) this.subscription.unsubscribe()
  }
});

export default targetVideoComponentGenerator;

