import React, { useRef, useMemo, useEffect, forwardRef } from 'react';
import videojs from "video.js";
import "video.js/dist/video-js.css";
import 'videojs-landscape-fullscreen';

const defaultOptions = {
  controls: true,
  // responsive: true,
  fluid: true,
  preload: 'auto',
  loop: false,
  autoplay: false,
  bigPlayButton: false
}

const VideoComponent = forwardRef(({ source }, ref) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const videoSource = useMemo(() => {
    console.log(source);

    if (!!source) {
      const checkVideoExtension = /^.+\.(mp4|webm|ogv)$/gi.exec(source);
      if (!checkVideoExtension) {
        return null;
      } else {
        const [_url, extension] = checkVideoExtension;
        return {
          src: _url,
          type: `video/${extension}`
        }
      }
    } else {
      return null;
    }
  }, [source])

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      // get aspect ratio from parent's size

      const _options = {
        ...defaultOptions,
        sources: [],
      };

      ref.current = playerRef.current = videojs(videoElement, _options, () => { });
      playerRef.current.landscapeFullscreen({
        fullscreen: {
          enterOnRotate: true,
          exitOnRotate: true,
          alwaysInLandscapeMode: true,
          iOS: true
        }
      })
      
      if (!!videoSource) {
        playerRef.current.src(videoSource.src);
        playerRef.current?.play();
      }
    } else {
      playerRef.current?.src(videoSource.src);
      playerRef.current?.play();
    }

  }, [videoSource, ref]);

  return (
    <div data-vjs-player style={{ background: 'transparent' }}>
      <video style={{
        objectFit: 'cover',
        background: 'transparent'
      }} className="video-js" ref={videoRef} playsInline />
    </div>
  )

}
)
export default VideoComponent;
