import React, { useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import VideoComponent from './VideoComponent';

const AframePopup = ({ data, onClosed }) => {
  const [open, setOpen] = useState(false);
  const videoRef = useRef();

  const content = useMemo(() => {
    if (!data || data.type !== "video") return <></>;
    setOpen(true);
    return <VideoComponent ref={videoRef} source={data.link} />;
  }, [data])

  const handleClosed = () => {
    setOpen(false);
    videoRef.current.pause();
    if (onClosed) onClosed();
  }

  return (
    <div style={{
      display: open ? 'flex' : 'none',
      zIndex: 2,
      position: 'fixed',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(0,0,0, .8)",
    }}>
      <div style={{
        display: 'inline-flex',
        width: '45px',
        height: '45px',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        right: '10px',
        top: '10px',
        fontSize: '1.5rem',
        zIndex: 9999,
        // backgroundColor: "rgba(0,0,0,.3)",
        borderRadius: '50%',
        lineHeight: 1,
        color: '#fff'
      }}
        onClick={handleClosed}
      >
        <FontAwesomeIcon icon={faXmark} />
      </div>

      <div style={{ flexGrow: 1 }}>
        {content}
      </div>
    </div>
  )
}

export default AframePopup;
