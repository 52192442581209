import { AFrameScene } from "./components/AFrameScene";
import html from "./views/ascene.html";
import AframePopup from "./components/AframePopup";
import targetVideoComponent from "./aFrameComponents/pause-vid-on-lost";
import { useCallback, useEffect, useMemo, useState } from "react";
import ImageGallery from './components/ImageGallery';
import { Subject } from "rxjs";

const appData = [
  {
    id: "JICBVideo",
    type: "video",
    link: "./assets/JICBVideo.mp4",
  },
  {
    id: "Akademietrailer",
    type: "video",
    link: "./assets/Akademietrailer.mp4",
  },
  {
    id: "TorwartCampVideo",
    type: "video",
    link: "./assets/DasTorwartCamp.mp4",
  },
  {
    id: "Leadership",
    type: "video",
    link: "./assets/Leadership.mp4",
  },
  {
    id: "ProLizenzVideo",
    type: "video",
    link: "./assets/ProLizenz.mp4",
  },
  {
    id: "AkademieweltenLink",
    type: "link",
    link: "https://www.dfb-akademie.de/ernaehrung/-/id-11009700"
  },
  {
    id: "Trackman",
    type: "video",
    link: "./assets/PerformanceDays.mp4",
  },
  {
    id: "SportdirektorenZeugnisverleihung",
    type: "video",
    link: "./assets/ZeugnisverleihungSportdirektoren.mp4",
  },
  {
    id: "PerformanceDays",
    type: "video",
    link: "./assets/Trackman.mp4",
  },
  {
    id: "RegenerationSchlaf",
    type: "images",
    link: [
      {
        // thumb: "./assets/Schlaf_01_thumb.png",
        src: "./assets/Schlaf_01.png",
        width: 1280,
        height: 720,
      },
      {
        // thumb: "./assets/Schlaf_02_thumb.png",
        src: "./assets/Schlaf_02.png",
        width: 1280,
        height: 720,
      },
      {
        // thumb: "./assets/Schlaf_03_thumb.png",
        src: "./assets/Schlaf_03.png",
        width: 1280,
        height: 720,
      },
    ]
  }
];

// image targets
// setTimeout(function() { ChangeImageTargets(); alert("Images changed")}, 10000);
// function ChangeImageTargets() {
//   XR8.XrController.configure({imageTargets: ['Akademietrailer', 'LinkAkademiewelten', 'AkademieStandardtraining', 'Leadership', 'PerformanceDays', 'ProLizenzVideo', 'RegenerationSchlaf', 'ZeugnisverleihungSportdirektoren', 'Trackman', 'DasTorwartCamp']});
// }

const imageTargets = [
  'Trackman',
  'Leadership',
  'ProLizenzVideo',
  'RegenerationSchlaf',
  'PerformanceDays',
  'Akademietrailer',
  'SportdirektorenZeugnisverleihung',
  'TorwartCampVideo',
  'AkademieweltenLink',
  'JICBVideo',
];

function App() {
  const [arLoaded, setArLoaded] = useState(false);
  const [data, setData] = useState(null);
  const contentToggleSub = useMemo(() => new Subject(), []);
  const toggleContent = useCallback((options = null) => {
    if (options && options.id) {
      const data = appData.find(x => x.id === options.id);
      console.log("data", data, options.id);
      console.log(data);
      if (data.type === "link") {
        console.log("link", data.type);
        const linkEl = document.createElement("a");
        linkEl.setAttribute("href", data.link);
        linkEl.setAttribute("target", "_blank");

        document.body.appendChild(linkEl);
        linkEl.click();
        linkEl.remove();
      } else {
        setData(data);
      }
    }
  }, []);

  const components = useMemo(() => {
    return [
      {
        name: "target-video",
        val: targetVideoComponent(toggleContent, contentToggleSub)
      }
    ]
  }, [toggleContent, contentToggleSub])

  const handleClosed = useCallback(() => {
    contentToggleSub.next();
    setData(null);
  }, [contentToggleSub])

  useEffect(() => {
    setTimeout(() => {
      window.XR8.XrController.configure({ disableWorldTracking: true });
      setArLoaded(true);
    }, 500)
  }, [])

  return (
    <div>
      {arLoaded && (<AFrameScene sceneHtml={html} components={components} imageTargets={imageTargets} />)}
      <AframePopup data={data} onClosed={handleClosed} />
      <ImageGallery data={data} onClosed={handleClosed} />
    </div>
  )
}

export default App;
